







import Vue from 'vue';
import { getLegacyAdminUrl } from '@/env';

export default Vue.extend({
  components: {},
  created: function () {
    const path = getLegacyAdminUrl() + '/static-message/metadata';
    window.open(path);
  },
});
