export interface AppVersion {
  minimumVersion: string;
  latestVersion: string;
}
export interface AppConfigParam {
  latestVersion: string;
  minimumVersion: string;
  deviceType: DeviceType;
}

export enum DeviceType {
  iOS = 'iOS',
  Android = 'Android',
}
