import gql from 'graphql-tag';

export const getAppVersion = gql`
  query appVersion($deviceType: MobileDeviceType!) {
    appVersion(deviceType: $deviceType) {
      minimumVersion
      latestVersion
    }
  }
`;

export const updateAppConfig = gql`
  mutation updateAppConfig($param: AppConfigParam!) {
    updateAppConfig(param: $param) {
      success
      message
    }
  }
`;
