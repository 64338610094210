


















import Vue from 'vue';
import { getLegacyHostUrl } from '@/env';

export default Vue.extend({
  components: {},
  data(): { legacyHostSsoUrl: string } {
    return {
      legacyHostSsoUrl: getLegacyHostUrl() + '/sso.html',
    };
  },
  methods: {
    onLoad() {
      const legacyHostBillMenuPath = getLegacyHostUrl() + '/bills/request';

      const iframe = document.getElementById(
        'ssoifr'
      ) as HTMLIFrameElement | null;
      const { accessToken, userId, hostId } = this.$store.state.userToken;

      iframe?.contentWindow?.postMessage(
        {
          userAccessToken: accessToken,
          userId,
          hostId,
        },
        '*'
      );

      window.open(legacyHostBillMenuPath);
    },
  },
});
