



































import InlineInput from '@/components/Forms/InlineInput.vue';
import InlineRadioInput from '@/components/Forms/InlineRadioInput.vue';
import InlineText from '@/components/Forms/InlineText.vue';
import Vue from 'vue';
import Instant from '@/components/Labels/Instant.vue';
import { getAppVersion, updateAppConfig } from '../graphqls/mobile';
import { AppVersion, DeviceType, AppConfigParam } from '../models/mobile';

export default Vue.extend({
  components: {
    InlineInput,
  },
  data(): { ios: AppVersion; android: AppVersion } {
    return {
      ios: {
        minimumVersion: '',
        latestVersion: '',
      },
      android: {
        minimumVersion: '',
        latestVersion: '',
      },
    };
  },
  apollo: {
    ios: {
      query: getAppVersion,
      variables(): { deviceType: DeviceType } {
        return {
          deviceType: DeviceType.iOS,
        };
      },
      update(data: { appVersion: AppVersion }): AppVersion {
        console.log(data);
        return data.appVersion;
      },
    },
    android: {
      query: getAppVersion,
      variables(): { deviceType: DeviceType } {
        return {
          deviceType: DeviceType.Android,
        };
      },
      update(data: { appVersion: AppVersion }): AppVersion {
        console.log(data);
        return data.appVersion;
      },
    },
  },
  methods: {
    save() {
      const saveConfig = async () => {
        try {
          const ios = await this.$apollo.mutate({
            mutation: updateAppConfig,
            variables: {
              param: {
                minimumVersion: this.ios.minimumVersion,
                latestVersion: this.ios.latestVersion,
                deviceType: DeviceType.iOS,
              },
            },
          });
          const android = await this.$apollo.mutate({
            mutation: updateAppConfig,
            variables: {
              param: {
                minimumVersion: this.android.minimumVersion,
                latestVersion: this.android.latestVersion,
                deviceType: DeviceType.Android,
              },
            },
          });

          this.$modal.show({
            title: '수정 성공!',
            type: 'info',
            //   message: err.message,
          });
        } catch (err) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'danger',
            message: err.message,
          });
        }
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        saveConfig
      );
    },
  },
});
